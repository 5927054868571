import React from 'react';
import { PLAN_NAME, PlanType } from 'src/pages/constants';
import {
  MealPlanVariant,
  Plan,
  WeeksPurchased,
} from 'src/pages/deprecated/payment/types';

export function dedupeObjectArray<T extends { [accessor: string]: any }>(
  objectList: Array<T>,
  accessor: string
): Array<T> {
  const deduped: Array<T> = [];
  objectList.forEach((item: T) => {
    if (
      !deduped.find((predicate: T) => predicate[accessor] === item[accessor])
    ) {
      deduped.push(item);
    }
  });
  return deduped;
}

export function findObjectInArray<T extends { [accessor: string]: any }>(
  predicate: any,
  objArray: Array<T>,
  accessor: string
): T | undefined {
  return objArray && objArray.find((item: T) => item[accessor] === predicate);
}

export function sortObjectListByAccessor<T extends { [accessor: string]: any }>(
  objectList: Array<T>,
  accessor: string
) {
  return objectList.sort((a, b) => {
    const varA = a[accessor].toLowerCase
      ? a[accessor].toLowerCase()
      : a[accessor];
    const varB = b[accessor].toLowerCase
      ? b[accessor].toLowerCase()
      : b[accessor];

    if (varA < varB) return -1;
    if (varA > varB) return 1;
    return 0;
  });
}

export function pushToArray(arr: any[], obj: { id: any }) {
  const newArr = arr;
  const index = arr.findIndex(e => e.id === obj.id);

  if (index === -1) {
    newArr.push(obj);
  } else {
    newArr[index] = obj;
  }
  return newArr;
}

export const getRecommendedSubscription = (
  scale: string,
  challenge: string, // 0 means standard, 1 means premium
  feeling: string, // 0 means standard, 1 means premium
  support: string, // 0 means standard, 1 means premium
  country: string, // 0 means standard, 1 means premium
  availablePlans: any
) => {
  if (!!availablePlans) {
    const months = parseInt(scale) >= 7 ? 6 : 3;
    let standardCount = 0;
    let premiumCount = 0;
    const plans = [];
    let finalPlanId = '';
    if (parseInt(challenge) > 0) {
      ++premiumCount;
    } else {
      ++standardCount;
    }
    if (parseInt(feeling) > 0) {
      ++premiumCount;
    } else {
      ++standardCount;
    }
    if (parseInt(support) > 0) {
      ++premiumCount;
    } else {
      ++standardCount;
    }

    if (premiumCount > standardCount || country !== 'Australia') {
      for (const key in availablePlans) {
        if (key.includes('Premium')) {
          plans.push(key);
        }
      }
    } else {
      for (const key in availablePlans) {
        if (key.includes('Standard')) {
          plans.push(key);
        }
      }
    }

    plans.forEach(item => {
      if (item.includes(months.toString())) {
        finalPlanId = item;
      }
    });
    return finalPlanId;
  }
};

export const getRecommendedSubscriptionSignup = (
  scale: string,
  challenge: string, // 0 means standard, 1 means premium
  feeling: string, // 0 means standard, 1 means premium
  support: string, // 0 means standard, 1 means premium
  country: string, // 0 means standard, 1 means premium
  availablePlans: Plan[]
) => {
  if (availablePlans) {
    const months = parseInt(scale) >= 7 ? 6 : 3;
    let standardCount = 0;
    let premiumCount = 0;
    const plans: string[] = [];
    let finalPlanId = '';
    if (parseInt(challenge) > 0) {
      ++premiumCount;
    } else {
      ++standardCount;
    }
    if (parseInt(feeling) > 0) {
      ++premiumCount;
    } else {
      ++standardCount;
    }
    if (parseInt(support) > 0) {
      ++premiumCount;
    } else {
      ++standardCount;
    }

    if (country === 'New Zealand') {
      availablePlans.forEach(plan => {
        if (plan.id?.includes('Standard')) {
          plans.push(plan.id);
        }
      });
    } else if (premiumCount > standardCount || country !== 'Australia') {
      availablePlans.forEach(plan => {
        if (plan.id?.includes('Premium')) {
          plans.push(plan.id);
        }
      });
    } else {
      availablePlans.forEach(plan => {
        if (plan.id?.includes('Standard')) {
          plans.push(plan.id);
        }
      });
    }

    plans.forEach(item => {
      if (item.includes(months.toString())) {
        finalPlanId = item;
      }
    });
    return finalPlanId;
  }
};

export const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const mobileWidth = 576;
  const desktopWidth = 1440;

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return {
    isMobile: width < mobileWidth,
    isDesktop: width >= desktopWidth,
    width,
  };
};

export const isUserFromAustralia = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const showStandardPlan = searchParams.get('showStandardPlan'); //to bypass the condition to check for user country and show standard plan

  if (process.env.NODE_ENV !== 'production' || showStandardPlan) {
    return true; // Apply this to production only to avoid checking standard plan for offshore developers
  }
  const country1 = searchParams.get('country');
  const country2 = searchParams.get('coutntry'); // this should be removed once we update the typeform field
  const country = country2 ?? country1;

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimezone.includes('Australia') || country === 'Australia';
};

export const isUserFromNewZealand = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const country = searchParams.get('country');

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // checks for the timezone falling under NZ
  const isNZUser =
    userTimezone.includes('Auckland') ||
    userTimezone.includes('Chatham') ||
    country === 'New Zealand';

  return isNZUser;
};

export type FinalPlanObject = {
  id: string;
  productId: string;
  weeklyMealPlanQty: number;
  unitAmountCents: number;
  unitAmount: string;
  unitAmountPerWeek: number;
  unitAmountPerDay: number;
  weeksPurchased: WeeksPurchased;
  interval: string;
  intervalCount: number;
  perCycleAmount: string | number;
};

export const getFullPlanObj = (
  availablePlans: Plan[],
  selectedPlanId: string,
  addonWeek: number
): FinalPlanObject | undefined => {
  const planObj = availablePlans.find((plan: Plan) => {
    return plan.id === selectedPlanId;
  });
  const unitAmountCents =
    (planObj?.mealPlanVariants &&
      planObj?.mealPlanVariants[addonWeek]?.unitAmountCents) ||
    0;
  const intervalCount = planObj?.intervalCount || 1;
  const perCycleAmount = unitAmountCents / 100 / intervalCount || 0;
  let er = /^-?[0-9]+$/;

  return (
    planObj && {
      interval: planObj.interval,
      intervalCount: planObj?.intervalCount,
      perCycleAmount: er.test(perCycleAmount.toString())
        ? perCycleAmount
        : perCycleAmount.toFixed(2),
      ...planObj?.mealPlanVariants[addonWeek],
    }
  );
};

export const PLAN_NAME_OBJ: any = {
  '1 week': 'Taster',
  '1 month': 'Kickstart',
  '2 month': 'Kickstart',
  '3 month': 'Elevate',
  '6 month': 'Transform',
};

export const returnFilteredPlans = (
  planList: Plan[],
  planType: PlanType,
  isNewZealandUser: boolean
) => {
  let filteredPlans;

  if (
    isNewZealandUser ||
    (planType && planType === 'standard' && isUserFromAustralia())
  ) {
    filteredPlans = planList.filter(plan =>
      PLAN_NAME.STANDARD_PLAN.includes(plan.id)
    );
  } else {
    filteredPlans = planList.filter(
      plan =>
        PLAN_NAME.PREMIUM_PLAN.includes(plan.id) ||
        PLAN_NAME.DAILY_TARGETS_ONLY_PLAN.includes(plan.id)
    );
  }
  return filteredPlans;
};

export const perCycleAmountBasedOnDuration = (
  amount: string | number,
  duration: string
): number => {
  if (
    duration.includes('3') ||
    duration.includes('2') ||
    duration.includes('6')
  ) {
    return Math.ceil(typeof amount === 'number' ? amount : parseFloat(amount));
  } else {
    return Number(amount);
  }
};

// data and events tracking
export const getEcommerceDataProperties = (
  planId: string,
  planList: Plan[],
  properties?: Record<string, any>,
  addOnWeek: number = 1
) => {
  const planDetail = planList.find(plan => plan.id === planId);

  const metaDataList = Object.values(
    planDetail?.mealPlanVariants ?? {}
  ) as MealPlanVariant[];
  const metaData = metaDataList[addOnWeek - 1];

  if (!planDetail) {
    return null;
  }

  // generate e-commerce data object
  return {
    currency: 'AUD',
    value: Number(metaData.unitAmount),
    items: [
      {
        item_id: metaData.id,
        product_id: metaData.productId,
        item_name: planDetail.id,
        item_brand: 'Equalution',
        item_list_id:
          PLAN_NAME_OBJ[`${planDetail.intervalCount} ${planDetail.interval}`],
        item_list_name:
          PLAN_NAME_OBJ[`${planDetail.intervalCount} ${planDetail.interval}`],
        price: Number(metaData.unitAmount),
        quantity: 1,
      },
    ],
    ...properties,
  };
};

export const calculateAge = (birthday: Date) => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const inchesToCentimeters = (inches: number): number => {
  return inches / 0.3937;
};

export const centimetersToFeetAndInches = (
  centimeters: number
): { feet: number; inches: number } => {
  const realFeet = (centimeters * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return { feet: feet, inches: inches };
};

export const poundsToKilograms = (pounds: number): number => {
  return parseFloat((pounds / 2.2046).toFixed(2));
};
export const kiloToPounds = (kilo: number): number => {
  return parseFloat((kilo * 2.2046).toFixed(2));
};

export const handleEncodingURL = () => {
  const finalURL = window.location.href.replace('+', '%2B');
  window.location.href = finalURL;
};

export const FAT_CONSTANTS = {
  FEMALE_16_39: 0.2,
  FEMALE_40_59: 0.22,
  MALE_16_39: 0.18,
  MALE_40_59: 0.2,
};

export const calculateLBM = ({
  weight,
  height,
  gender,
}: {
  weight: number;
  height: number;
  gender: 'MALE' | 'FEMALE' | undefined;
}): number => {
  if (gender === 'MALE') {
    return 0.407 * weight + 0.267 * height - 19.2;
  }
  return 0.252 * weight + 0.473 * height - 48.3;
};
export type SuggestedWeightParams = {
  gender: 'MALE' | 'FEMALE' | undefined;
  age: number;
  lbm: number;
};

export const calculateSuggestedWeight = ({
  gender,
  age,
  lbm,
}: SuggestedWeightParams): number => {
  let fatConstant = 0;
  if (gender === 'FEMALE') {
    fatConstant =
      age <= 39 ? FAT_CONSTANTS.FEMALE_16_39 : FAT_CONSTANTS.FEMALE_40_59;
  } else if (gender === 'MALE') {
    fatConstant =
      age <= 39 ? FAT_CONSTANTS.MALE_16_39 : FAT_CONSTANTS.MALE_40_59;
  }
  return lbm / (1 - fatConstant);
};

export const isNumericOnly = (val: string) => {
  const re = new RegExp(/^\d*\.?\d*$/);
  return val ? re.test(val) : true;
};

export const hideIntercomElement = () => {
  const intercomElement: any = document.getElementsByClassName(
    'intercom-lightweight-app'
  )[0];
  if (intercomElement) {
    intercomElement.style.display = 'none';
  }
};

export const showIntercomElement = () => {
  const intercomElement: any = document.getElementsByClassName(
    'intercom-lightweight-app'
  )[0];
  if (intercomElement) {
    intercomElement.style.display = 'block';
  }
};

export const filterStipeErrorMessage = (error: string) => {
  if (!error) {
    return '';
  }
  return error.slice(error.indexOf(':') + 1);
};

export const checkNZUser = () => {
  return isUserFromNewZealand();
};

export const getCurrencySymbol = (showNZSymbol?: boolean) => {
  if (showNZSymbol !== undefined) {
    return showNZSymbol ? 'NZ$' : '$';
  }
  return checkNZUser() ? 'NZ$' : '$';
};

export const isFreeTrialURL = () =>
  window.location.pathname.includes('/freetrial');
