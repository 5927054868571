import React, { useState, Fragment } from 'react';
import { Button, Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import { PaymentMethod } from '@stripe/stripe-js';
import { Product } from '../../../deprecated/payment/types';
import Loader from '../../../../components/content/loader';
import Popup from '../../../../components/content/popup/popup';
import ContentSection from '../../../../components/content/content-section';
import ContentWrapper from '../../../../components/content/content-wrapper';
import styles from './extras-popup.module.scss';
import ExtrasCounter from './extras-counter';
import { IAppState } from '../../../../store';
import paymentAPI from '../../../deprecated/payment/api';
import { getCurrencySymbol } from 'src/lib/utils';

interface OwnProps {
  selectedProduct: Product;
  consumableProducts: Product[];
  paymentMethod: PaymentMethod | undefined;
  totalPrice: number;
  selectedPriceID: string;
  selectedPriceIDs: string[];
  setSelectedPriceIDs: (vals: string[]) => void;
  setPopupVisible: (val: boolean) => void;
  setTotalPrice: (totalPrice: number) => void;
  setSelectedProduct: (selectedProduct: Product) => void;
  setSuccessMessage: (val: string) => void;
}

type Props = OwnProps;

const additionalMealPlanOptionNominal = 'One-off meal plan';
const ExtrasPopup = ({
  selectedProduct,
  paymentMethod,
  totalPrice,
  selectedPriceID,
  selectedPriceIDs,
  setSelectedPriceIDs,
  setPopupVisible,
  consumableProducts,
  setSelectedProduct,
  setTotalPrice,
  setSuccessMessage,
}: Props): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const user = useSelector((state: IAppState) => state.auth?.user);
  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);

    try {
      // Create Payment Customer
      if (user && paymentMethod) {
        const { data } = await paymentAPI.post('stripe/customer/create', {
          email: user.email,
          userID: user.id,
        });
        // Process Payment
        await paymentAPI.post('stripe/invoice/purchase', {
          paymentMethodID: paymentMethod.id,
          customerID: data.customer?.id,
          priceIDs: selectedPriceIDs,
          promoID: null,
          userID: user.id,
          isChallenge: false,
        });
        setErrorMessage('');
        setSuccessMessage(
          `Your add-ons have successfully been added to your account.`
        );

        const tmpSelectedProduct = selectedProduct;
        tmpSelectedProduct.quantity = 0;
        tmpSelectedProduct.totalPriceCents = 0;
        setSelectedProduct(tmpSelectedProduct);
        setTotalPrice(0);
        setPopupVisible(false);
      }
    } catch (e) {
      console.error('EXCEPTION', e);
      setErrorMessage(
        'There was an error processing your payment, please try again or contact us'
      );
      setSuccessMessage('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Popup>
      <ContentWrapper className={styles.background} collapseAt="sm">
        <ContentSection className={styles.extrasPopup} collapseAt="sm">
          {!isSubmitting ? (
            <>
              <Button
                className={styles.close}
                disabled={isSubmitting}
                onClick={(): void => setPopupVisible(false)}
              />
              <h3>Add-ons</h3>
              {selectedProduct && (
                <>
                  <ExtrasCounter
                    label={
                      selectedProduct.name.toUpperCase() ===
                      'Additional Meal Plan Option'.toUpperCase()
                        ? additionalMealPlanOptionNominal
                        : selectedProduct.name
                    }
                    defaultQty={
                      selectedProduct.quantity ? selectedProduct.quantity : 0
                    }
                    onQuantityChange={(qty, type): void => {
                      const tmpArray = selectedPriceIDs;
                      const tmpSelectedProduct = selectedProduct;
                      tmpSelectedProduct.quantity = qty;
                      tmpSelectedProduct.totalPriceCents =
                        selectedProduct.prices[selectedPriceID]
                          .unitAmountCents * qty;
                      if (type === 'increment') {
                        tmpArray.push(selectedPriceID);
                        setTotalPrice(
                          totalPrice +
                            selectedProduct.prices[selectedPriceID]
                              .unitAmountCents
                        );
                      }
                      if (type === 'decrement') {
                        const index = tmpArray.indexOf(selectedPriceID);
                        tmpArray.splice(index, 1);
                        setTotalPrice(
                          totalPrice -
                            selectedProduct.prices[selectedPriceID]
                              .unitAmountCents
                        );
                      }
                      setSelectedProduct(tmpSelectedProduct);
                      setSelectedPriceIDs([...tmpArray]);
                    }}
                  />
                  {consumableProducts &&
                    Object.entries(consumableProducts).map(
                      ([id, product]: [string, Product]) => {
                        return product.totalPriceCents > 0 ? (
                          <div className={styles.field} key={product.id}>
                            <div className={styles.inner}>
                              <div className={styles.text}>{product.name}</div>
                              <div
                                className={
                                  styles.price
                                }>{`${getCurrencySymbol()}${(product.totalPriceCents
                                ? product.totalPriceCents / 100
                                : 0
                              ).toFixed(2)}`}</div>
                            </div>
                          </div>
                        ) : (
                          <Fragment key={product.id} />
                        );
                      }
                    )}
                  <hr />
                  <div className={styles.field}>
                    <div className={styles.inner}>
                      <div className={styles.text}>Total</div>
                      <div
                        className={
                          styles.price
                        }>{`${getCurrencySymbol()}${(totalPrice
                        ? totalPrice / 100
                        : 0
                      ).toFixed(2)}`}</div>
                    </div>
                  </div>
                  {errorMessage !== '' && (
                    <Alert color="danger">{errorMessage}</Alert>
                  )}
                </>
              )}
              <Button
                block
                disabled={isSubmitting || !totalPrice}
                onClick={(): void => {
                  handleSubmit();
                }}>
                Pay
              </Button>{' '}
            </>
          ) : (
            <Loader />
          )}
        </ContentSection>
      </ContentWrapper>
    </Popup>
  );
};

export default ExtrasPopup;
