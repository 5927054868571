import React, { FunctionComponent, PropsWithChildren } from 'react';
import logo from '../../assets/images/logo-only.svg';
import styles from './loader.module.scss';

interface OwnProps {
  style?: any;
}

type Props = OwnProps;

const Loader: FunctionComponent<Props> = ({
  style = {},
}: PropsWithChildren<Props>) => {
  return (
    <div className={styles.loader} style={style}>
      <img className={styles.spin} src={logo} alt="logo" />
    </div>
  );
};

export default Loader;
