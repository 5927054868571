import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StandardPlanSubscriptionPage from './module/StandardPlanFlow';
import PremiumPlanSubscriptionPage from './module/PremiumPlanFlow';

import {
  PLANS,
  PlanType,
  USER_STATUS,
  projectDuration,
  projectName,
} from '../constants';
import { Plan, Plans } from '../deprecated/payment/types';
import Product from 'src/models/Product';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  setAllPlansSorted,
  setSignupStep,
  setSelectedPlanId,
  setAvailablePlansWithoutDefaultCheck,
  setIsFitherProjectUser,
} from 'src/store/signup';
import {
  getEcommerceDataProperties,
  getRecommendedSubscriptionSignup,
  isFreeTrialURL,
  isUserFromAustralia,
  isUserFromNewZealand,
  returnFilteredPlans,
} from 'src/lib/utils';
import { EVENTS, track } from 'src/util/track';
import moment from 'moment';
import { Layout } from 'src/components/layout-wrapper/Layout';
import Loader from 'src/components/content/loader';
import { optimizely } from 'src/setupOptimizely';
import { useDecision } from '@optimizely/react-sdk';
import {
  checkQuizStatus,
  trackCheckoutService,
} from 'src/services/quiz-service';
import { useHistory } from 'react-router-dom';
import { updateQuizData } from 'src/store/quiz';
import User from 'src/models/User';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { stripeProductsApi } from 'src/services';

export interface SubscriptionPageProps {
  planType: PlanType;
  setPlanType: (planType: PlanType) => void;
  addonWeek: number;
  setAddonWeek: (value: number) => void;
  isStandardPlan: boolean;
  onCheckoutClick: () => void;
  availablePlans: Array<Plan>;
  selectedPlanId: string;
  showTwoStepProcess: boolean;
  country: string | null;
}

const SubscriptionPage = () => {
  const history = useHistory();
  const userEmail = useSelector((state: IAppState) => state.auth?.user?.email);

  // url params
  const searchParams = new URLSearchParams(window.location.search);

  // typeform quiz params
  const scale = searchParams.get('scale');
  const challengeParam = searchParams.get('challenge');
  const feeling = searchParams.get('feeling');
  const support = searchParams.get('support');
  const country = searchParams.get('country');
  const isFromWebsite = searchParams.get('website');
  const [decision] = useDecision('website_programs_bypass_quiz', {
    autoUpdate: true,
  });

  const isNewZealandUser = isUserFromNewZealand() || country === 'New Zealand';

  // plan params
  const planTypeParam = searchParams.get('planType')?.toLowerCase() as PlanType;
  const durationParam = searchParams.get('duration') ?? '3';

  // qr code project params
  const projectNameParam = searchParams.get('project')?.toLowerCase();

  //email check
  const emailParam = searchParams.get('email');

  // check validity for FitHer project
  const isValidForFitherProject = useMemo(() => {
    const validProjectName = projectNameParam === projectName;
    const validDuration = moment().isBetween(
      projectDuration.start,
      projectDuration.end
    );

    return validProjectName && validDuration;
  }, [projectNameParam]);

  const [planType, setPlanType] = useState<PlanType>(
    planTypeParam || 'premium'
  );
  const [sortedPlans, setSortedPlans] = useState<Plan[]>([]);
  const [addonWeek, setAddonWeek] = useState(0);
  const [isInitialRender, toggleIsInitialRender] = useState(true);

  const [showStandardPlan, setShowStandardPlan] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const {
    availablePlans,
    selectedPlanId,
    allPlansSorted,
    addonWeek: mealAddonWeek,
  } = useAppSelector(state => state.signUp);
  const user = useAppSelector(state => state.auth?.user);
  const dispatch = useAppDispatch();

  const isStandardPlan = planType === 'standard';
  const showTwoStepProcess =
    user?.status === USER_STATUS.PROSPECT ||
    user?.status === USER_STATUS.INACTIVE;

  const onCheckoutClick = async () => {
    track(EVENTS.subscriptionPlan, {
      isStandardPlanSelected: isStandardPlan,
      selectedPlan: selectedPlanId,
      mealAddonWeek,
    });
    track(EVENTS.beginCheckout, { ecommerce: null });
    track(EVENTS.beginCheckout, {
      logged_in_status: Boolean(user),
      ecommerce: getEcommerceDataProperties(selectedPlanId, availablePlans),
    });
    if (emailParam || userEmail) {
      const emailToSend = emailParam ?? userEmail ?? '';

      if (emailToSend) {
        await trackCheckoutService({ email: emailToSend });
      }
    }
    if (showTwoStepProcess) {
      dispatch(setSignupStep(3));
      return;
    }
    dispatch(setSignupStep(2));
  };

  const sortPlans = useCallback(
    (plans: Plans) => {
      const sortedPlansArr = Object.values(plans).sort((a, b): number => {
        if (
          a.id.includes(PLANS.STANDARD_PLAN) &&
          !b.id.includes(PLANS.STANDARD_PLAN)
        ) {
          return -1;
        } else if (
          !a.id.includes(PLANS.STANDARD_PLAN) &&
          b.id.includes(PLANS.STANDARD_PLAN)
        ) {
          return 1;
        } else if (
          a.id.includes(PLANS.STANDARD_PLAN) &&
          b.id.includes(PLANS.STANDARD_PLAN)
        ) {
          return a.intervalCount - b.intervalCount;
        } else if (
          !a.id.includes(PLANS.STANDARD_PLAN) &&
          !b.id.includes(PLANS.STANDARD_PLAN) &&
          a.interval === 'week' &&
          b.interval !== 'week'
        ) {
          return a.intervalCount - b.intervalCount;
        } else {
          return -1;
        }
      });
      setSortedPlans(sortedPlansArr);
      dispatch(setAllPlansSorted(sortedPlansArr));
    },
    [dispatch]
  );

  // TODO Extract Function to Utils
  const extractPlans = (currentPlans: Plans, productData: Product): Plans => {
    return Object.values(productData.prices).reduce<Plans>(
      (accumulator, price) => {
        if (
          !price.active ||
          !price.recurring ||
          price.recurring.weeklyMealPlanQty === null
        ) {
          return accumulator;
        }

        const planName = `${price.metadata.planName}`;

        // If plan does not exist, create plan
        if (!accumulator[planName]) {
          accumulator[planName] = {
            id: planName,
            interval: price.recurring.interval,
            intervalCount: price.recurring.intervalCount,
            mealPlanVariants: {},
            active: price.active,
            metadata: {},
            currency_options: price.currency_options || [],
          };
        }
        // const unitAmount = checkNZUser()
        //   ? price?.currency_options?.nzd?.unit_amount / 100 ||
        //     price?.currency_options?.aud?.unit_amount / 100
        //   : price?.currency_options?.aud?.unit_amount / 100;

        // Add meal plan variant
        accumulator[planName].mealPlanVariants[
          price.recurring.weeklyMealPlanQty
        ] = {
          id: price.id,
          productId: productData.id,
          unitAmount: price.unitAmount,
          unitAmountCents: price.unitAmountCents,
          // unitAmount: unitAmount?.toString() || price.unitAmount,
          // unitAmountCents: unitAmount * 100 || price.unitAmountCents,
          unitAmountPerDay: price.unitAmountPerDay,
          unitAmountPerWeek: price.unitAmountPerWeek,
          weeklyMealPlanQty: price.recurring.weeklyMealPlanQty,
          weeksPurchased: price.weeksPurchased,
        };

        return accumulator;
      },
      currentPlans
    );
  };

  // set plan and navigate to step 2 if fither project user
  useEffect(() => {
    const plan = allPlansSorted.find(
      plan =>
        plan.id.includes('Standard') &&
        plan.interval === 'month' &&
        plan.intervalCount === 1
    );

    track(EVENTS.fitHerProjectUser);

    if (isValidForFitherProject && plan) {
      dispatch(setIsFitherProjectUser(true));

      setTimeout(() => {
        dispatch(setSelectedPlanId(plan.id));
        dispatch(setSignupStep(2));
      }, 700);
    }
  }, [allPlansSorted, dispatch, isValidForFitherProject]);

  // Get Product Data on Mount
  useEffect(() => {
    let mounted = true;
    (async (): Promise<void> => {
      if (!allPlansSorted.length) {
        try {
          setIsLoading(true);
          // Get Products Data

          const response = await stripeProductsApi();
          const productsData = response.data.products;

          if (!mounted) return;

          const tempPlans: Plans = {};
          // Find Product
          Object.values(productsData).forEach((productEntry: unknown) => {
            const tempProduct: Product = productEntry as Product;
            if (
              tempProduct?.metadata?.EQPlanID === '1' ||
              tempProduct?.metadata?.EQPlanID === '4'
            ) {
              extractPlans(tempPlans, tempProduct);
            }
          });
          sortPlans(tempPlans);
        } catch (e) {
          console.log('getProducts error', e);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSortedPlans([...allPlansSorted]);
      }
    })();
    return (): void => {
      mounted = false;
    };
  }, [sortPlans, allPlansSorted]);

  useEffect(() => {
    if (isInitialRender && sortedPlans.length) {
      const filteredPlans = returnFilteredPlans(
        sortedPlans,
        isValidForFitherProject ? 'standard' : planType,
        isNewZealandUser
      );
      dispatch(
        setAvailablePlansWithoutDefaultCheck([...filteredPlans].reverse())
      );
    }
  }, [
    sortedPlans,
    planType,
    dispatch,
    isInitialRender,
    isValidForFitherProject,
    isNewZealandUser,
  ]);

  useEffect(() => {
    if (isInitialRender && availablePlans.length) {
      let isDefaultPlanSelected = false;
      //logic to handle query params for quiz logic and select subscription
      if (!!support && !!scale && !!challengeParam && !!feeling && !!country) {
        track(EVENTS.typeformSubmit);
        track(EVENTS.quizComplete, { quiz: 'typeform' });

        const recommendedSubscription = getRecommendedSubscriptionSignup(
          scale,
          challengeParam,
          feeling,
          support,
          country,
          allPlansSorted
        );

        if (recommendedSubscription) {
          if (
            isNewZealandUser ||
            (recommendedSubscription.includes('Standard') &&
              isUserFromAustralia())
          ) {
            setPlanType('standard');
            setShowStandardPlan(true);
          }
          dispatch(setSelectedPlanId(recommendedSubscription));
          isDefaultPlanSelected = true;
        }
      }
      if (planTypeParam && durationParam) {
        if (
          isNewZealandUser ||
          (planTypeParam === 'standard' && isUserFromAustralia())
        ) {
          setShowStandardPlan(true);
          setPlanType('standard');
        }
        const duration = parseInt(durationParam);

        const plan = availablePlans.find(
          plan => plan.interval === 'month' && plan.intervalCount === duration
        );

        if (plan?.id) {
          dispatch(setSelectedPlanId(plan.id));
          isDefaultPlanSelected = true;
        }
      }
      if (isNewZealandUser) {
        setShowStandardPlan(true);
        setPlanType('standard');
      }
      if (!isDefaultPlanSelected) {
        dispatch(setSelectedPlanId(availablePlans[0].id));
      }

      setTimeout(() => {
        toggleIsInitialRender(false);
      }, 500);
    }
  }, [
    allPlansSorted,
    dispatch,
    durationParam,
    planTypeParam,
    showStandardPlan,
    isInitialRender,
    challengeParam,
    country,
    feeling,
    scale,
    selectedPlanId,
    support,
    availablePlans,
    isNewZealandUser,
  ]);

  useEffect(() => {
    if (isFromWebsite) {
      if (decision?.variationKey === 'off') {
        history.replace('/quiz');
      }
    } else {
      optimizely.track('completed_quiz'); // event to track for quiz flow conversion
    }
  }, [decision?.variationKey, history, isFromWebsite]);

  const checkEmailQuizStatus = useCallback(
    async (email: string, user: User | null) => {
      const response = await checkQuizStatus({ email });
      if (response?.data) {
        if (
          user &&
          user.status === USER_STATUS.PROSPECT &&
          !response.data.isQuizExpired
        ) {
          dispatch(updateQuizData({ isQuiz: true }));
        } else if (response.data.isQuizExpired) {
          if (!isFreeTrialURL()) {
            history.replace('/quiz?email=' + encodeURIComponent(email));
          }
        } else if (response.data.data) {
          dispatch(updateQuizData({ isQuiz: true }));
        }
      }
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (!scale) {
      // if scale is set, meaning user rendered from type form, so ignore checking quiz status
      if (emailParam) {
        checkEmailQuizStatus(emailParam, null);
      } else if (user?.email) {
        checkEmailQuizStatus(user.email, user);
      }
    }
  }, [checkEmailQuizStatus, emailParam, user, scale]);

  const props: SubscriptionPageProps = {
    planType,
    setPlanType,
    addonWeek,
    setAddonWeek,
    isStandardPlan,
    onCheckoutClick,
    availablePlans,
    selectedPlanId,
    showTwoStepProcess,
    country,
  };

  if (isValidForFitherProject || loading) {
    return (
      <Layout module="login">
        <Loader />
      </Layout>
    );
  }

  if (showStandardPlan) {
    return (
      <StandardPlanSubscriptionPage
        {...props}
        selectedPlanId={selectedPlanId}
        availablePlans={availablePlans}
        setPlanType={setPlanType}
      />
    );
  }
  return (
    <PremiumPlanSubscriptionPage
      {...props}
      selectedPlanId={selectedPlanId}
      availablePlans={availablePlans}
      setPlanType={setPlanType}
    />
  );
};

export default SubscriptionPage;
