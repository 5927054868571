import React from 'react';

import styles from './order-component.module.scss';
import { Coupon, Plan, Plans } from 'src/pages/deprecated/payment/types';
import PromoCode from '../../../../components/fields/promo-code/promo-code';
import SubscriptionPopup from 'src/pages/signup/steps/subscription/subscription-popup';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { Button } from 'reactstrap';
import { PLAN_NAME } from 'src/pages/constants';
import { getCurrencySymbol } from 'src/lib/utils';
import { useAppSelector } from 'src/store/hooks';

//step 2  of  payment page Payment summary and order summary

interface OwnProps {
  selectedPlan: string;
  weeklyMealPlanQty: number;
  plans: Plans;
  coupon: Coupon | undefined;
  planRequest: string;
  priceId: string;
  setCoupon: (coupon: Coupon | undefined) => void;
  futureCommencement?: boolean;
  setFutureCommencement?: (futureCommencement: boolean) => void;
  prefillCode?: string;
  setSelectedPlan: (id: string) => void;
  setWeeklyMealPlanQty: (qty: number) => void;
  plan: Plan;
  finalPrice: number;
  planName: string;
  setPopupVisible: (val: boolean) => void;
  popupVisible: boolean;
}

const OrderComponent = ({
  selectedPlan,
  weeklyMealPlanQty,
  plans,
  coupon,
  planRequest,
  priceId,
  setCoupon,
  futureCommencement,
  setFutureCommencement,
  setSelectedPlan,
  setWeeklyMealPlanQty,
  plan,
  finalPrice,
  planName,
  popupVisible,
  setPopupVisible,
}: OwnProps) => {
  const user = useSelector((state: IAppState) => state.auth?.user);
  const { showFreeTrial } = useAppSelector(state => state.signUp);
  const variant = plan?.mealPlanVariants[weeklyMealPlanQty];

  const AVG_WEEKS_PER_MONTH = 4.35;
  const WEEKS_PER_MONTH = 4;

  const perMonthCost = () => {
    let variantAmount;
    if (!PLAN_NAME.STANDARD_PLAN.includes(plan.id)) {
      variantAmount = parseFloat(
        (variant.unitAmountPerWeek * AVG_WEEKS_PER_MONTH).toFixed(2)
      );
    } else {
      variantAmount = variant.unitAmountPerWeek * WEEKS_PER_MONTH;
    }
    return Math.round(variantAmount);
  };

  const getPrefillCode = (queryString: string): string => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const codeParam = params.get(queryString);
    if (codeParam === 'true') {
      return 'EQMITCH';
    }
    return '';
  };
  return (
    <div className={styles.orderColumn}>
      <p className={styles.orderColumnTitle}>Order Summary</p>
      <div className={styles.orderDetailContainer}>
        <div className={styles.orderTitle}>{planName}</div>
        <div className={styles.orderBreakdown}>
          <div className={styles.planDetailContainer}>
            <div className={`${styles.orderCell} ${styles.monthText}`}>
              {plan?.intervalCount > 1
                ? `${plan?.intervalCount} ${plan?.interval}s`
                : '1 ' + plan?.interval}
            </div>
            <div className={`${styles.orderCell} ${styles.monthDetail}`}>
              {coupon?.duration.toLowerCase() === 'once' ? (
                <>
                  <div className={styles.pricingContainer}>
                    <span className={styles.mediumPrice}>
                      {getCurrencySymbol()}
                      {finalPrice < 0 ? '0.00' : finalPrice.toFixed(2)}
                    </span>{' '}
                    <span className={styles.subscriptionDesc}>
                      for first payment.
                    </span>
                  </div>
                  <div className={styles.subscriptionDesc}>
                    Normally billed {getCurrencySymbol()}
                    {finalPrice < 0
                      ? '0.00'
                      : parseFloat(variant?.unitAmount).toFixed(2)}
                    <br />
                    {' every '}
                    {plan?.intervalCount > 1
                      ? `${plan?.intervalCount} ${plan?.interval}s`
                      : plan?.interval}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.pricingContainer}>
                    <span className={styles.price}>
                      {finalPrice === 0 ? (
                        '0'
                      ) : (
                        <>
                          {plan.interval === 'week'
                            ? finalPrice.toFixed(2)
                            : plan?.id && perMonthCost()}
                        </>
                      )}
                    </span>
                    /{plan?.interval}
                  </div>
                  <div className={styles.subscriptionDesc}>
                    Billed as{' ' + getCurrencySymbol()}
                    {finalPrice < 0 ? '0.00' : finalPrice.toFixed(2)}
                    <br />
                    {' every '}
                    {plan?.intervalCount > 1
                      ? `${plan?.intervalCount} ${plan?.interval}s`
                      : plan?.interval}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.orderCell + ' ' + styles.changeButton}>
            <Button
              size="sm"
              outline={true}
              onClick={(): void => {
                setCoupon(undefined);
                setPopupVisible(true);
              }}>
              Change
            </Button>
          </div>
        </div>
      </div>
      {!showFreeTrial && (
        <>
          <div style={{ margin: '20px 0px 10px' }}>PROMO CODE</div>
          <PromoCode
            priceId={priceId}
            productId={variant.productId}
            coupon={coupon}
            setCoupon={setCoupon}
            futureCommencement={futureCommencement}
            setFutureCommencement={setFutureCommencement}
            prefillCode={getPrefillCode('challengeCoupon')}
            isFromSignUp={true}
            finalPrice={finalPrice}
          />
        </>
      )}

      {popupVisible && (
        <SubscriptionPopup
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          weeklyMealPlanQty={weeklyMealPlanQty}
          setWeeklyMealPlanQty={setWeeklyMealPlanQty}
          plans={plans}
          setPopupVisible={setPopupVisible}
          user={user}
          planRequest={planRequest}
          isFromSignUp={true}
        />
      )}
    </div>
  );
};

export default OrderComponent;
