import React, { useEffect, useRef, useState } from 'react';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import paymentAPI from '../../../deprecated/payment/api';
import { IAppState } from '../../../../store';
import { EVENTS, track } from 'src/util/track';
import history from '../../../../lib/history';
import User from 'src/models/User';
import { Coupon } from 'src/pages/deprecated/payment/types';
import { TagData } from 'src/store/signup-workflow/types';
// import { validateCoupons } from '../../signup';
import styles from './wallet-component.module.scss';
// import { useAppSelector } from 'src/store/hooks';
import axios from 'axios';
import { decodeCookie } from 'src/lib/cookie-parser';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { validateCoupons } from './payment-step';
import { checkQuizStatus } from 'src/services/quiz-service';
import { setPaymentIntentLoading } from 'src/store/payment';

interface WalletSubscriptionType {
  user: User;
  paymentMethod: PaymentMethod;
  priceId: string;
  coupon: Coupon | undefined;
  isChallengeAccepted: boolean;
  totalPrice: string;
  futureCommencement: boolean;
  tagId: number;
  productId: string;
  challengeObject: TagData | null;
}
interface OwnProps {
  priceId: string;
  planName: string;
  finalPrice: number;
  coupon: Coupon | undefined;
  isChallengeAccepted: boolean;
  tagId: number;
  challengeObject: TagData | null;
  futureCommencement: boolean;
  productId: string;
  stripeCustomer: any;
  setWalletPaymentIntentId: (paymentIntent: string) => void;
  popupVisible: boolean;
  isNoIntentAvailable: boolean;
  setIsNoIntentAvailable: (isNoIntentAvailable: boolean) => void;
}

const WalletComponent = ({
  priceId,
  finalPrice,
  planName,
  coupon,
  isChallengeAccepted,
  tagId,
  challengeObject,
  futureCommencement,
  productId,
  stripeCustomer,
  setWalletPaymentIntentId,
  popupVisible,
  isNoIntentAvailable,
  setIsNoIntentAvailable,
}: OwnProps) => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const { isFitherProjectUser, showFreeTrial } = useAppSelector(
    state => state.signUp
  );

  const stripe = useStripe();

  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [paymentDetail, setPaymentDetail] = useState<any>(null);
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  let cancelTokenSource: any = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // to trigger only when the popUp is closed
    if (stripe && stripeCustomer && !popupVisible) {
      createPaymentIntent(priceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stripe,
    stripeCustomer,
    popupVisible,
    priceId,
    finalPrice,
    setWalletPaymentIntentId,
  ]);

  // once the payment method has been set, we can submit the form
  useEffect(() => {
    const gPayPaymentIntent = async (paymentDetail: any) => {
      if (stripe) {
        const pr = stripe.paymentRequest({
          country: paymentDetail.account_country,
          currency: paymentDetail.currency,
          total: { label: planName, amount: paymentDetail.amount_remaining },
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
          if (result) {
            setPaymentRequest(pr);
          }
        });
      }
    };

    if (paymentDetail) {
      gPayPaymentIntent(paymentDetail);
    }
  }, [paymentDetail, planName, stripe]);

  const createPaymentIntent = async (priceId: string) => {
    toggleIsLoading(true);
    const cookie = decodeCookie();
    if (cancelTokenSource.current) {
      //check if any intent request is going on
      cancelTokenSource.current.cancel(
        'Cancelled previous payment intent request'
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      dispatch(setPaymentIntentLoading(true)); //update redux
      /// Used custom axios as axios instant was not working with cancel token
      const data: any = await axios.post(
        'stripe/create/payment-intent',
        {
          priceId: priceId,
          customerId: stripeCustomer?.id,
          coupon: coupon?.id,
          isZeroPayment: finalPrice === 0,
          isFreeTrial: showFreeTrial, //free trial
        },
        {
          headers: { Authorization: (cookie && cookie.token) || '' },
          cancelToken: cancelTokenSource.current.token,
        }
      );
      dispatch(setPaymentIntentLoading(false));

      setClientSecret(data?.data?.data.clientSecret);
      setIsNoIntentAvailable(data?.data?.data.noIntent);

      setPaymentDetail(data?.data?.data?.subscription?.latest_invoice);
      setWalletPaymentIntentId(
        data?.data?.data?.subscription?.latest_invoice?.payment_intent?.id
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Previous intent request is cancelled');
      } else {
        throw error;
      }
    }
    setTimeout(() => {
      toggleIsLoading(false);
    }, 500);
  };

  const completeWalletPayment = async (props: WalletSubscriptionType) => {
    const {
      user,
      paymentMethod,
      priceId,
      coupon,
      isChallengeAccepted,
      totalPrice,
      futureCommencement,
      tagId,
      productId,
      challengeObject,
    } = props;
    const errorMsg = validateCoupons(priceId, coupon?.metadata);
    if (errorMsg) {
      toggleIsLoading(false);
      return { errorMsg };
    }

    let quizFinalStatus = false;

    //checking quiz status before final payment
    const quizStatusRes = await checkQuizStatus({ email: user.email });
    if (quizStatusRes.status === 200) {
      if (quizStatusRes?.data?.data) {
        quizFinalStatus = true;
      }
    }

    let purchaseInvoicePayload: any = {
      paymentMethodID: paymentMethod.id,
      customerID: stripeCustomer?.id,
      productId: productId,
      priceIDs: [priceId],
      promoID: coupon?.id ?? null,
      userID: user.id,
      isChallenge: !!isChallengeAccepted,
      paymentIntentId: paymentDetail.payment_intent.id,
      isCardPayment: false, //make it true only if is card payment
      futureCommencement,
      isQuiz: quizFinalStatus, // from web, this will be true
      isFreeTrial: showFreeTrial,
    };
    if (!!isChallengeAccepted && challengeObject?.id) {
      purchaseInvoicePayload.challenge_ids = [challengeObject.id];
    }

    // Process Payment
    await paymentAPI.post(
      'stripe/invoice/purchase/wallets',
      purchaseInvoicePayload
    );

    // Update Google Analytics
    if (user.status !== 'PROSPECT') {
      history.push('/confirmation?ref=TransactionComplete', {
        tagId,
        futureCommencement,
        priceId,
        coupon,
        totalPrice,
        dataLayer: track(EVENTS.programPurchase, {
          currency: 'AUD',
          value: totalPrice,
          purchaseComplete: 'ClientRenewal',
          priceIDs: [priceId],
          isFitherProjectUser,
        }),
      });
    } else {
      history.push('/confirmation?ref=TransactionComplete', {
        tagId,
        futureCommencement,
        priceId,
        coupon,
        totalPrice,
        dataLayer: track(EVENTS.programPurchase, {
          currency: 'AUD',
          value: totalPrice,
          purchaseComplete: 'ClientNewPurchase',
          priceIDs: [priceId],
          isFitherProjectUser,
        }),
      });
    }
  };

  const watchPaymentStatus = (clientSecret: string | null) => {
    toggleIsLoading(true);
    if (paymentRequest && stripe && clientSecret) {
      paymentRequest.on(
        'paymentmethod',
        async (ev: { paymentMethod: any; complete: (arg0: any) => void }) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { error: confirmError } = await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );

          if (!user || confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
            toggleIsLoading(false);
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            completeWalletPayment({
              user,
              paymentMethod: ev.paymentMethod,
              priceId,
              coupon,
              isChallengeAccepted,
              totalPrice: finalPrice.toString(),
              futureCommencement,
              tagId,
              productId,
              challengeObject,
            });
          }
        }
      );
      paymentRequest.on('cancel', () => {
        toggleIsLoading(false);
      });
    }
  };

  return (
    <>
      {!!paymentRequest && stripeCustomer && !isNoIntentAvailable && (
        <>
          <div className={styles.walletContainer}>
            <div
              style={{
                display: 'block',
                width: '100%',
                opacity: isLoading ? '0.3' : 1,
              }}>
              <PaymentRequestButtonElement
                options={{
                  paymentRequest,
                  style: { paymentRequestButton: { theme: 'dark' } },
                }}
                onClick={() => !isLoading && watchPaymentStatus(clientSecret)}
              />
            </div>
          </div>
          <div className={`${styles.or}`}> OR </div>
        </>
      )}
    </>
  );
};

export default WalletComponent;
