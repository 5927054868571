import React, { useEffect, useState } from 'react';
import SharedRecipeDetail from './sharedRecipeDetail';
import SharedRecipeInfo from './SharedRecipeInfo';
import { AxiosResponse } from 'axios';
import paymentAPI from '../deprecated/payment/api';
import { Alert } from 'reactstrap';
import history from 'src/lib/history';
import { Meal } from './types';
import Loader from 'src/components/content/loader';
import SharedRecipeExpired from './SharedRecipeExpired';

const SharedRecipe = () => {
  const [userEmail, setUserEmail] = useState('');
  const [recipeData, setRecipeData] = useState<Meal | null>(null);
  const [loading, toggleLoading] = useState(true);
  const [showExpiredError, setShowExpiredError] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const recipe_id = searchParams.get('recipe-id');
  const shared_token = searchParams.get('shared_token');
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shared_token, recipe_id]);

  useEffect(() => {
    if (userEmail) {
      submitUserEmail(userEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  const submitUserEmail = async (guestEmail: string) => {
    await paymentAPI.post(`/api/shared-recipe/add-viewer`, {
      recipe_id,
      shared_token,
      email: guestEmail,
    });
  };

  const fetchData = async () => {
    if (!shared_token || !recipe_id) {
      history.push('/');
    }
    try {
      const recipeResponse: AxiosResponse = await paymentAPI.get(
        `/api/shared-recipe?shared_token=${shared_token}`
      );
      if (recipeResponse?.data?.recipe) {
        setRecipeData(recipeResponse.data?.recipe);
      }
    } catch (error) {
      setShowExpiredError(true);
    }
    toggleLoading(false);
  };

  return (
    <>
      {!shared_token || !recipe_id ? (
        <Alert color="danger">
          Wrong data passed. Please check URL properly.
        </Alert>
      ) : loading ? (
        <Loader />
      ) : showExpiredError ? (
        <SharedRecipeExpired />
      ) : userEmail ? (
        <SharedRecipeDetail recipeData={recipeData} />
      ) : (
        <SharedRecipeInfo recipeData={recipeData} setUserEmail={setUserEmail} />
      )}
    </>
  );
};

export default SharedRecipe;
