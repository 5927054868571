import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import logo from 'src/assets/images/equ-logo-cream.svg';
import greenCheck from 'src/assets/icons/checkmark-green.svg';
import RecipeSampleImage from 'src/assets/images/recipe-samples.png';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import colors from 'src/styles/equalution/colors';
import { useViewport } from 'src/lib/utils';
import { Meal } from './types';
import history from 'src/lib/history';

function ClaimMyFreeTrialBottom({ isMobile }: { readonly isMobile: boolean }) {
  return (
    <Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '20px 0',
          backgroundColor: '#FFEEDA',
          zIndex: 1000,
        }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.SALAD,
            color: colors.WHITE,
            padding: '10px 20px',
            width: isMobile ? '300px' : '400px',
            fontSize: '20px',
            borderRadius: '50px',
            textTransform: 'none',
          }}
          onClick={() =>
            history.push('/freetrial?planType=standard&duration=3')
          }>
          Claim my free trial
        </Button>
      </Box>
      <Box sx={{ backgroundColor: '#FFEEDA', height: '100px' }}></Box>
    </Box>
  );
}

function LimitedTimeOffer({ isMobile }: { readonly isMobile: boolean }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#FFEEDA',
        alignItems: 'center',
        paddingBottom: '5%',
      }}>
      <Box
        sx={{
          width: isMobile ? '100%' : '640px',
        }}>
        <img
          src={RecipeSampleImage}
          alt="Preview"
          style={{
            width: isMobile ? '90%' : '600px',
            margin: '0 auto',
            marginTop: isMobile ? '-50px' : '-90px',
            display: 'flex',
          }}
        />
        <Box
          sx={{
            borderRadius: '12px',
            backgroundColor: colors.CREAM,
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            width: '190px',
            justifySelf: 'center',
            margin: '20px auto 10px',
          }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '24px',
              alignSelf: 'center',
            }}>
            Limited Time Offer
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            fontSize: isMobile ? '22px' : '40px',
            fontWeight: '600',
            lineHeight: isMobile ? '30px' : '50px',
            alignSelf: 'center',
            textAlign: 'center',
            marginBottom: '10px',
          }}>
          Ready to transform your meals and your health?
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: isMobile ? '18px' : '22px',
            fontWeight: '600',
            lineHeight: isMobile ? '22px' : '40px',
            alignSelf: 'center',
            textAlign: 'center',
            color: colors.SALAD,
            margin: '10px 0px',
          }}>
          Start your 10-day free trial today and unlock:
        </Typography>
        <Box sx={{ maxWidth: isMobile ? '90%' : '465px', margin: '0 auto' }}>
          <TickList text="Full-day tailored meal plans with unlimited swaps" />
          <TickList text="8,000+ healthy, delicious recipes" />
          <TickList text="Track your meals, water, and progress effortlessly" />
          <TickList text="No commitments. Cancel anytime" />
        </Box>
      </Box>
    </Box>
  );
}

const TickList = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '36px',
      }}>
      <img src={greenCheck} alt="tick" style={{ marginRight: '10px' }} />
      {text}
    </Box>
  );
};

function Faq({ isMobile }: { readonly isMobile: boolean }) {
  const [expanded, setExpanded] = useState<number | null>(null);
  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null);
    };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        backgroundColor: colors.CREAM_HALF,
        padding: '3% 0px',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',
          width: isMobile ? '90%' : '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography
          variant="body2"
          sx={{
            fontSize: isMobile ? '22px' : '40px',
            fontWeight: '600',
            lineHeight: isMobile ? '30px' : '50px',
            alignSelf: 'center',
            textAlign: 'center',
          }}>
          Want to know more?
        </Typography>

        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
              '&:before': { display: 'none' }, // Remove default MUI shadow line
              width: isMobile ? '90%' : '600px',
            }}>
            <AccordionSummary
              expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
              sx={{
                fontWeight: '600',
                fontSize: '18px',
                minHeight: '48px',
                width: '100%',
                '& .MuiAccordionSummary-content': { margin: '12px 0' },
              }}>
              {faq.question}
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontSize="16px">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}

function Reviews({ isMobile }: { readonly isMobile: boolean }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: colors.OFF_WHITE,
        alignItems: 'center',
        padding: '3% 0px',
        width: '100%',
      }}>
      <Typography
        variant="body2"
        sx={{
          fontSize: isMobile ? '22px' : '40px',
          fontWeight: '600',
          lineHeight: isMobile ? '30px' : '50px',
          alignSelf: 'center',
          textAlign: 'center',
          marginBottom: '2%',
        }}>
        Real people, real results
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gridTemplateColumns: 'unset',
          gap: '14px',
          px: '10px',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar
          maxWidth: '1325px',
          width: '100%',
          margin: '2% auto',
        }}>
        {reviews.map((review, index) => (
          <Card
            key={index}
            sx={{
              width: '300px',
              height: '115px',
              flexShrink: 0, // Prevent shrinking in scroll
              p: '12px',
              borderRadius: '12px',
              boxShadow: 0,
              backgroundColor: colors.WHITE,
            }}>
            <CardContent sx={{ p: '4px', paddingBottom: '0px' }}>
              <svg
                width="124"
                height="24"
                viewBox="0 0 124 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.5489 2.92705C10.8483 2.00574 12.1517 2.00574 12.4511 2.92705L13.8574 7.25532C13.9913 7.66734 14.3752 7.9463 14.8085 7.9463H19.3595C20.3282 7.9463 20.731 9.18592 19.9473 9.75532L16.2654 12.4303C15.9149 12.685 15.7683 13.1364 15.9021 13.5484L17.3085 17.8766C17.6078 18.798 16.5533 19.5641 15.7696 18.9947L12.0878 16.3197C11.7373 16.065 11.2627 16.065 10.9122 16.3197L7.23037 18.9947C6.44665 19.5641 5.39217 18.798 5.69153 17.8766L7.09787 13.5484C7.23174 13.1364 7.08508 12.685 6.7346 12.4303L3.05275 9.75532C2.26904 9.18592 2.67181 7.9463 3.64053 7.9463H8.19155C8.62477 7.9463 9.00873 7.66734 9.1426 7.25532L10.5489 2.92705Z"
                  fill="#01965E"
                />
                <path
                  d="M35.5489 2.92705C35.8483 2.00574 37.1517 2.00574 37.4511 2.92705L38.8574 7.25532C38.9913 7.66734 39.3752 7.9463 39.8085 7.9463H44.3595C45.3282 7.9463 45.731 9.18592 44.9473 9.75532L41.2654 12.4303C40.9149 12.685 40.7683 13.1364 40.9021 13.5484L42.3085 17.8766C42.6078 18.798 41.5533 19.5641 40.7696 18.9947L37.0878 16.3197C36.7373 16.065 36.2627 16.065 35.9122 16.3197L32.2304 18.9947C31.4467 19.5641 30.3922 18.798 30.6915 17.8766L32.0979 13.5484C32.2317 13.1364 32.0851 12.685 31.7346 12.4303L28.0527 9.75532C27.269 9.18592 27.6718 7.9463 28.6405 7.9463H33.1915C33.6248 7.9463 34.0087 7.66734 34.1426 7.25532L35.5489 2.92705Z"
                  fill="#01965E"
                />
                <path
                  d="M60.5489 2.92705C60.8483 2.00574 62.1517 2.00574 62.4511 2.92705L63.8574 7.25532C63.9913 7.66734 64.3752 7.9463 64.8085 7.9463H69.3595C70.3282 7.9463 70.731 9.18592 69.9473 9.75532L66.2654 12.4303C65.9149 12.685 65.7683 13.1364 65.9021 13.5484L67.3085 17.8766C67.6078 18.798 66.5533 19.5641 65.7696 18.9947L62.0878 16.3197C61.7373 16.065 61.2627 16.065 60.9122 16.3197L57.2304 18.9947C56.4467 19.5641 55.3922 18.798 55.6915 17.8766L57.0979 13.5484C57.2317 13.1364 57.0851 12.685 56.7346 12.4303L53.0527 9.75532C52.269 9.18592 52.6718 7.9463 53.6405 7.9463H58.1915C58.6248 7.9463 59.0087 7.66734 59.1426 7.25532L60.5489 2.92705Z"
                  fill="#01965E"
                />
                <path
                  d="M86.0489 2.92705C86.3483 2.00574 87.6517 2.00574 87.9511 2.92705L89.4697 7.60081C89.6035 8.01284 89.9875 8.2918 90.4207 8.2918H95.335C96.3037 8.2918 96.7065 9.53141 95.9228 10.1008L91.947 12.9894C91.5966 13.244 91.4499 13.6954 91.5838 14.1074L93.1024 18.7812C93.4017 19.7025 92.3472 20.4686 91.5635 19.8992L87.5878 17.0106C87.2373 16.756 86.7627 16.756 86.4122 17.0106L82.4365 19.8992C81.6528 20.4686 80.5983 19.7025 80.8976 18.7812L82.4162 14.1074C82.5501 13.6954 82.4034 13.244 82.053 12.9894L78.0772 10.1008C77.2935 9.53141 77.6963 8.2918 78.665 8.2918H83.5793C84.0125 8.2918 84.3965 8.01284 84.5303 7.60081L86.0489 2.92705Z"
                  fill="#01965E"
                />
                <path
                  d="M111.549 2.92705C111.848 2.00574 113.152 2.00574 113.451 2.92705L114.857 7.25532C114.991 7.66734 115.375 7.9463 115.808 7.9463H120.359C121.328 7.9463 121.731 9.18592 120.947 9.75532L117.265 12.4303C116.915 12.685 116.768 13.1364 116.902 13.5484L118.308 17.8766C118.608 18.798 117.553 19.5641 116.77 18.9947L113.088 16.3197C112.737 16.065 112.263 16.065 111.912 16.3197L108.23 18.9947C107.447 19.5641 106.392 18.798 106.692 17.8766L108.098 13.5484C108.232 13.1364 108.085 12.685 107.735 12.4303L104.053 9.75532C103.269 9.18592 103.672 7.9463 104.641 7.9463H109.192C109.625 7.9463 110.009 7.66734 110.143 7.25532L111.549 2.92705Z"
                  fill="#01965E"
                />
              </svg>
              <Typography
                fontWeight="bold"
                sx={{ fontSize: '14px', fontWeight: '600' }}>
                {review.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: '12px', lineHeight: '20px' }}>
                {review.text}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

function RecipeSection({
  isMobile,
  recipeData,
}: {
  readonly isMobile: boolean;
  readonly recipeData: Meal | null;
}) {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '100%',
          height: isMobile ? '250px' : '400px',
          backgroundImage: `url('${recipeData?.presigned_image}')`, // Replace with actual path
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          backgroundColor: '#FCF9EF',
          alignItems: 'center',
          paddingBottom: '140px',
        }}>
        <Box sx={{ width: isMobile ? '90%' : '640px' }}>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #ccc',
              marginTop: '20px',
            }}>
            <p>{Math.round(recipeData?.calories ?? 0)} cals</p>
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                style={{ marginRight: '5px' }}
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 23.5C18.0751 23.5 23 18.5751 23 12.5C23 6.42487 18.0751 1.5 12 1.5C5.92487 1.5 1 6.42487 1 12.5C1 18.5751 5.92487 23.5 12 23.5ZM12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5Z"
                  fill="#101820"
                />
                <path
                  d="M12 6.5L12 12.5"
                  stroke="#101820"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M16 16.5L12 12.5"
                  stroke="#101820"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              {recipeData?.recipe_cook_time_minute ?? 1} min
            </p>
            <p>{recipeData?.servings ?? 1} serving</p>
          </Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: '600',
              fontSize: '28px',
              color: colors.OFF_BLACK,
              marginTop: '20px',
            }}>
            {recipeData?.name}
          </Typography>
          <Box
            sx={{
              width: isMobile ? '90%' : '100%',
              padding: '10px',
              display: 'flex',
              flexDirection: 'row',
              fontSize: '18px',
              fontWeight: '600',
              backgroundColor: colors.CREAM_HALF,
              borderRadius: '16px',
              margin: '20px 0px',
            }}>
            <Box
              sx={{
                flex: 1,
                borderRight: '1px solid #ccc',
                textAlign: 'center',
              }}>
              <Typography variant="body2">
                {Math.round(recipeData?.protein || 0)}g
              </Typography>
              <Typography
                variant="body2"
                style={{ fontSize: '14px', fontWeight: '400' }}>
                protein
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                borderRight: '1px solid #ccc',
                textAlign: 'center',
              }}>
              <Typography variant="body2">
                {Math.round(recipeData?.fat || 0)}g
              </Typography>
              <Typography
                variant="body2"
                style={{ fontSize: '14px', fontWeight: '400' }}>
                fat
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                borderRight: '1px solid #ccc',
                textAlign: 'center',
              }}>
              <Typography variant="body2">
                {Math.round(recipeData?.carbs || 0)}g
              </Typography>
              <Typography
                variant="body2"
                style={{ fontSize: '14px', fontWeight: '400' }}>
                carbs
              </Typography>
            </Box>
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <Typography variant="body2">
                {Math.round(recipeData?.fiber || 0)}g
              </Typography>
              <Typography
                variant="body2"
                style={{ fontSize: '14px', fontWeight: '400' }}>
                fibre
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body2"
            sx={{
              fontSize: '18px',
              fontWeight: '600',
              alignSelf: 'flex-start',
              marginTop: '15px',
            }}>
            Ingredients
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '16px',
              fontWeight: 'normal',
              alignSelf: 'flex-start',
              color: colors.BLUEBERRY_HALF,
              lineHeight: '28px',
            }}>
            {recipeData?.ingredients.map(ingredient => {
              return (
                <span key={ingredient.id}>
                  {getAmountWithMeasure(
                    `${ingredient.amount}`,
                    ingredient.unit_of_measure
                  )}{' '}
                  {ingredient.name}
                  <br />
                </span>
              );
            })}
          </Typography>
          {!!recipeData?.meal_steps?.length && (
            <>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  alignSelf: 'flex-start',
                  marginTop: '15px',
                }}>
                Cooking Method
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '16px',
                  fontWeight: 'normal',
                  alignSelf: 'flex-start',
                  color: colors.BLUEBERRY_HALF,
                }}>
                <ol>
                  {recipeData?.meal_steps.map(mealStep => {
                    return (
                      <li
                        key={mealStep.id}
                        style={{ lineHeight: '24px', marginBottom: '10px' }}>
                        {mealStep.description}
                      </li>
                    );
                  })}
                </ol>
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

function ClaimMyFreeTrialTop({ isMobile }: { readonly isMobile: boolean }) {
  return (
    <Box
      sx={{
        backgroundColor: colors.SALAD_HALF,
        display: 'flex',
        width: '100%',
        padding: '10px',
        margin: '0 auto',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: '600',
          fontSize: isMobile ? '16px' : '20px',
          color: colors.OFF_BLACK,
          lineHeight: isMobile ? '20px' : '28px',
        }}>
        Limited Time Offer
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: '500',
          fontSize: isMobile ? '14px' : '18px',
          color: colors.OFF_BLACK,
          lineHeight: isMobile ? '20px' : '28px',
          marginTop: '10px',
          width: isMobile ? '90%' : 'auto',
        }}>
        Start your <b>10-day free trial</b> in the equ app and unlock 8000+
        recipes tailored to your health goals!
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: colors.OFF_BLACK,
          color: colors.WHITE,
          marginTop: '15px',
          padding: '10px 20px',
          width: isMobile ? '300px' : '400px',
          fontSize: isMobile ? '14px' : '16px',
          borderRadius: '50px',
          textTransform: 'none',
        }}
        onClick={() => history.push(`/freetrial?planType=standard&duration=3`)}>
        Claim my free trial!
      </Button>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: '400',
          fontSize: isMobile ? '12px' : '14px',
          color: colors.OFF_BLACK,
          lineHeight: isMobile ? '20px' : '28px',
          marginTop: '10px',
        }}>
        No commitments. Cancel anytime.
      </Typography>
    </Box>
  );
}

function Header({ isMobile }: { readonly isMobile: boolean }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: colors.OFF_BLACK,
      }}>
      <Box
        sx={{
          backgroundColor: colors.OFF_BLACK,
          display: 'flex',
          width: '100%',
          padding: '10px',
          paddingLeft: isMobile ? '10px' : '5%',
        }}>
        <img src={logo} alt="logo" />
      </Box>
    </Box>
  );
}

export const isRaw = (name: string): boolean => {
  if (!name) {
    return false;
  }
  const lowerName = (name ?? '').toLowerCase();
  return (
    lowerName === 'grams' ||
    lowerName === 'gram' ||
    lowerName === 'oz' ||
    lowerName === 'ml' ||
    lowerName === 'mls' ||
    lowerName === 'tsp' ||
    lowerName === 'square'
  );
};

export const getShorthandMeasure = (name: string): string => {
  if (name.toLowerCase().includes('gram')) {
    return 'g';
  } else if (name.toLowerCase().includes('ml')) {
    return 'ml';
  } else if (name.toLowerCase().includes('tsp')) {
    return 'tsp';
  } else if (name.toLowerCase().includes('square')) {
    return 'sq';
  }
  return name;
};

const reviews = [
  {
    name: 'Lauradam3105',
    text: 'Love this app so much, it helps me feel in control of my day and allows me to be flexible and enjoy life and food.',
  },
  {
    name: 'Bell21',
    text: "For the first time in my life I have lost weight without feeling like I'm on a diet.",
  },
  {
    name: 'Jessmid',
    text: "The meals are so enjoyable and I am actually sticking to the program because I don't feel like I'm missing out.",
  },
  {
    name: 'Phoebec099',
    text: "They're so supportive of you learning to blend your lifestyle with your health - giving tips around how to enjoy a night out in moderation.",
  },
];

const faqs = [
  {
    question: 'How is equ personalised?',
    answer:
      'equ provides personalised meal plans based on your unique goals, preferences, and dietary restrictions. You’ll receive a weekly meal plan that is scientifically calculated using your body data to ensure you’re consuming the optimal amount of daily calories and macronutrients. Your plans will evolve as your body and goals change, ensuring sustainable and realistic results.',
  },
  {
    question: 'How can equ support my health goals?',
    answer:
      "Our unique program is designed to provide structure, guidance, and accountability to help you achieve your health goals—whether that’s fat loss, muscle gain, maintenance, or improved energy levels. We take the guesswork out of nutrition by providing meal plans that are tailored to your daily energy and macronutrient needs. The food diary and weekly check-ins will support your journey by ensuring you're on track and consistently accountable to your goals.",
  },
  {
    question: 'Is it easy to cancel my free trial?',
    answer:
      'Yes, our free trial is commitment-free! Simply manage your subscription in the app’s settings. You won’t be charged as long as you cancel before the trial expires, and there are no hidden fees. If you need help, our support team is always here to assist by emailing support@joinequ.com.',
  },
];

const getAmountWithMeasure = (amt: string, measure: string) =>
  isRaw(measure) ? `${amt}${getShorthandMeasure(measure)}` : `${amt}`;

const SharedRecipeDetail = ({ recipeData }: { recipeData: Meal | null }) => {
  const { isMobile } = useViewport();

  return (
    <>
      <Header isMobile={isMobile} />
      <Box>
        <ClaimMyFreeTrialTop isMobile={isMobile} />
        <RecipeSection isMobile={isMobile} recipeData={recipeData} />
        <LimitedTimeOffer isMobile={isMobile} />
        <Reviews isMobile={isMobile} />
        <Faq isMobile={isMobile} />
        <ClaimMyFreeTrialBottom isMobile={isMobile} />
      </Box>
    </>
  );
};

export default SharedRecipeDetail;
