import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ExpiredRecipe from 'src/assets/images/expired-recipe.png';
import { useViewport } from 'src/lib/utils';
import colors from 'src/styles/equalution/colors';
import history from 'src/lib/history';

const SharedRecipeExpired = () => {
    const { isMobile } = useViewport();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                color: colors.OFF_BLACK,
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: colors.CREAM_HALF,
            }}>
            <img
                src={ExpiredRecipe}
                alt="Preview"
                style={{
                    width: isMobile ? '70%' : '300px',
                    margin: '0 auto',
                    marginTop: isMobile ? '-50px' : '-90px',
                    display: 'flex',
                }}
            />
            <Typography
                variant="body2"
                sx={{
                    fontSize: '26px',
                    fontWeight: '600',
                    margin: '20px 0px',
                    alignSelf: 'center',
                }}>
                Link Expired
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: '400',
                    width: isMobile ? '70%' : '600px',
                    textAlign: 'center',
                }}>
                Sorry this recipe is no longer viewable. You can ask your friend to
                resend the link or sign up to our exclusive 10-day free trial to unlock
                8000+ recipes today!
            </Typography>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: colors.OFF_BLACK,
                    color: colors.WHITE,
                    marginTop: '20px',
                    padding: '10px 20px',
                    width: isMobile ? '300px' : '400px',
                    fontSize: isMobile ? '14px' : '16px',
                    borderRadius: '50px',
                    textTransform: 'none',
                }}
                onClick={() => history.push('/freetrial?planType=standard&duration=3')}>
                Claim my free trial!
            </Button>
        </Box>
    );
};

export default SharedRecipeExpired;
