import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import styles from './header-new.module.scss';
import logo from '../../assets/images/logo-new.svg';
import TextButton from './modules/text-button';
import UserButton from './modules/user-button';
import { IAppState } from '../../store';
import { Box } from '@mui/material';
import colors from 'src/styles/equalution/colors';
import { useLocation } from 'react-router-dom';

export interface HeaderProps {
  module?: 'login' | 'signup';
  hideLogo?: boolean;
  hideAccount?: boolean;
  style?: CSSProperties;
}

/**
 * Displays a Header with a logo and sign-in button
 * @param props: any
 */
const HeaderNew = ({
  module,
  hideLogo,
  hideAccount,
  ...attributes
}: HeaderProps): JSX.Element => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );
  const location = useLocation();

  let component;

  if (isAuthenticated && user) {
    component = <UserButton hideAccount={hideAccount} />;
  } else if (module === 'login') {
    component = (
      <TextButton
        text="Already have an account?"
        buttonText="Login"
        path="/login-account"
        from={location.pathname}
      />
    );
  } else if (module === 'signup') {
    component = (
      <TextButton
        text="Don't have an account yet?"
        buttonText="Sign up"
        path="/quiz"
        textColor={colors.OFF_BLACK}
      />
    );
  } else {
    component = <></>;
  }

  return (
    <header className={styles.header} {...attributes}>
      <div className={styles.inner}>
        {hideLogo ? (
          <Box />
        ) : (
          <a href="https://joinequ.com/">
            <img src={logo} alt="logo" className={styles.logo} />
          </a>
        )}
        {component}
      </div>
    </header>
  );
};

HeaderNew.defaultProps = {
  module: undefined,
  style: {},
};

export default HeaderNew;
