import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import logo from '../../assets/images/equ-logo-cream.svg';
import colors from 'src/styles/equalution/colors';
import { useViewport } from 'src/lib/utils';
import { Helmet } from 'react-helmet-async';
import { Meal } from './types';

const SharedRecipeInfo = ({
  recipeData,
  setUserEmail,
}: {
  recipeData: Meal | null;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const { isMobile } = useViewport();
  const metaTitle = recipeData?.name;
  const metaDescription = `Checkout recipe and sign up today!`;
  const metaImage = recipeData?.presigned_image; // Dynamic image
  const metaUrl = window.location.origin;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = () => {
    setUserEmail(email);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    if (value && emailRegex.test(value)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };
  return (
    <>
      <Helmet>
        {/* Open Graph Meta Tags for Slack */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:type" content="website" />

        {/* Twitter Card (Optional) */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Box
          sx={{
            backgroundColor: colors.OFF_BLACK,
            display: 'flex',
            width: '100%',
            padding: '10px',
            paddingLeft: isMobile ? '10px' : '5%',
          }}>
          <img src={logo} alt="logo" />
        </Box>
        <Box>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              height: isMobile ? '350px' : '400px',
              backgroundImage: `url('${metaImage}')`, // Replace with actual path
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              textAlign: 'center',
            }}>
            <Box
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: isMobile ? '80px' : '120px',
                background: '#00000099',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTopLeftRadius: '50%',
                borderTopRightRadius: '50%',
                flexDirection: 'column',
              }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: '700',
                  fontSize: isMobile ? '16px' : '30px',
                  color: colors.SALAD_HALF,
                }}>
                {recipeData?.name}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '500',
                  fontSize: isMobile ? '12px' : '20px',
                  color: colors.WHITE,
                }}>
                {Math.round(recipeData?.calories || 0)} Calories |{' '}
                {Math.round(recipeData?.protein || 0)}g Protein |{' '}
                {Math.round(recipeData?.fat || 0)}g Fat |{' '}
                {Math.round(recipeData?.carbs || 0)}g Carbs
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            backgroundColor: '#FCF9EF',
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '90%',
              flexDirection: 'column',
              backgroundColor: '#FCF9EF',
              alignItems: 'center',
              textAlign: 'center',
              margin: '0 auto',
            }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: '600',
                fontSize: isMobile ? '22px' : '28px',
                color: colors.OFF_BLACK,
                marginTop: '25px',
              }}>
              Your next favourite dish is one step away!
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Enter email"
              fullWidth
              sx={{
                width: isMobile ? '300px' : '400px',
                backgroundColor: colors.CREAM_HALF,
                borderRadius: '12px',
                marginTop: '20px',
                fontSize: isMobile ? '14px' : '15px',
                '& .MuiOutlinedInput-root': {
                  border: 'none',
                  borderRadius: '0px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              value={email}
              onChange={e => handleEmailChange(e.target.value)}
            />
            <Button
              variant="contained"
              disabled={!isValidEmail}
              onClick={handleSubmit}
              sx={{
                backgroundColor: !isValidEmail
                  ? '#B5B5B1 !important'
                  : colors.OFF_BLACK,
                color: `${colors.WHITE} !important`,
                marginTop: '20px',
                padding: '10px 20px',
                width: isMobile ? '300px' : '400px',
                fontSize: isMobile ? '16px' : '20px',
                borderRadius: '50px',
                textTransform: 'none',
              }}>
              Unlock recipe
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SharedRecipeInfo;
