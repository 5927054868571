import { Box, Divider, Typography } from '@mui/material';
import React, { FC } from 'react';
import { PLAN_DETAILS_TEXTS } from 'src/pages/constants';
import colors from 'src/styles/equalution/colors';
import Fonts from 'src/styles/equalution/fonts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import {
  FinalPlanObject,
  PLAN_NAME_OBJ,
  getCurrencySymbol,
  getFullPlanObj,
  perCycleAmountBasedOnDuration,
  useViewport,
} from 'src/lib/utils';
import { useAppSelector } from 'src/store/hooks';
import moment from 'moment';

export const BillingText = ({
  selectedPlanId,
  finalPlanObj,
}: {
  selectedPlanId: string;
  finalPlanObj: FinalPlanObject | undefined;
}) => {
  const { isMobile } = useViewport();
  const { couponObj } = useAppSelector(state => state.signUp);
  if (!finalPlanObj) {
    return <></>;
  }
  const shouldTextChange: boolean =
    couponObj && couponObj?.duration?.toLowerCase() === 'once';
  const unitAmount = shouldTextChange
    ? (finalPlanObj.unitAmountCents / 100).toFixed(2)
    : parseFloat(finalPlanObj.unitAmount).toFixed(2);
  if (selectedPlanId?.toLowerCase().includes('week')) {
    return (
      <Box>
        {shouldTextChange && 'Normally '} billed {getCurrencySymbol()}
        {unitAmount} weekly
      </Box>
    );
  }
  if (
    selectedPlanId?.toLowerCase().includes('month') &&
    finalPlanObj?.intervalCount === 1
  ) {
    return (
      <Box>
        {shouldTextChange && 'Normally '} billed {getCurrencySymbol()}
        {unitAmount} monthly
      </Box>
    );
  }

  return (
    <Box>
      {shouldTextChange && 'Normally '} billed as {getCurrencySymbol()}
      {unitAmount} {isMobile && <br />} every {finalPlanObj?.intervalCount}{' '}
      {finalPlanObj?.interval}s
    </Box>
  );
};

const PlanDetail: FC = () => {
  const { availablePlans, selectedPlanId, addonWeek, showFreeTrial } =
    useAppSelector(state => state.signUp);

  const finalPlanObj = getFullPlanObj(
    availablePlans,
    selectedPlanId,
    addonWeek
  );
  const { isMobile } = useViewport();
  const [showDetails, setShowDetails] = React.useState(false);

  if (!finalPlanObj) {
    return null;
  }
  const planType = selectedPlanId?.toLowerCase().includes('standard')
    ? 'standard'
    : 'premium';
  const currentPlanDescriptions = PLAN_DETAILS_TEXTS[planType].details;
  const intervalString = `${finalPlanObj.intervalCount} ${
    finalPlanObj.interval
  }${finalPlanObj.intervalCount > 1 ? 's' : ''}`;

  const standardShortDesc = () => {
    switch (selectedPlanId) {
      case 'Standard Plan - 3 Months':
        return PLAN_DETAILS_TEXTS.standard.shortDesc3Month;
      case 'Standard Plan - 6 Months':
        return PLAN_DETAILS_TEXTS.standard.shortDesc6Month;
      case 'Standard Plan - 1 Month':
        return PLAN_DETAILS_TEXTS.standard.shortDesc1Month;
      default:
        return '';
    }
  };

  const premiumShortDesc = () => {
    switch (selectedPlanId) {
      case 'Premium Plan - 3 Months':
        return PLAN_DETAILS_TEXTS.premium.shortDesc3Month;
      case 'Premium Plan - 6 Months':
        return PLAN_DETAILS_TEXTS.premium.shortDesc6Month;
      case 'Premium Plan - 2 Months':
        return PLAN_DETAILS_TEXTS.premium.shortDesc2Month;
      case 'Premium Plan - Weekly':
        return PLAN_DETAILS_TEXTS.premium.shortDescWeekly;
      default:
        return '';
    }
  };

  return (
    <Box
      bgcolor={colors.OFF_WHITE}
      borderRadius="20px"
      sx={{ minWidth: { sx: '100%', md: '490px' } }}>
      <Typography
        sx={{
          fontFamily: Fonts.FontType.regular,
          background:
            planType === 'standard' ? colors.ORANGE_HALF : colors.SALAD,
          fontWeight: 700,
          fontSize: '16px',
          padding: '9px 24px',
          textTransform: 'uppercase',
          borderRadius: '20px 20px 0 0',
          color: planType === 'standard' ? colors.OFF_BLACK : colors.WHITE,
        }}>
        {planType}
      </Typography>
      <Box sx={{ padding: '24px' }}>
        {showFreeTrial && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: isMobile ? '28px' : '32px',
                    color: colors.SALAD,
                    fontWeight: 600,
                    fontFamily: Fonts.FontType.regular,
                    lineHeight: 1,
                    transform: 'uppercase',
                  }}>
                  Try for free
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: colors.OFF_BLACK,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    fontFamily: Fonts.FontType.regular,
                    letterSpacing: '2px',
                    marginTop: '2px',
                  }}>
                  10-DAY FREE TRIAL
                </Typography>
              </Box>
              <Box>
                <Box sx={{ textAlign: 'right', minWidth: 100 }}>
                  <Typography
                    sx={{
                      fontSize: '34px',
                      color: colors.OFF_BLACK,
                      fontWeight: 'bold',
                      lineHeight: 1,
                      fontFamily: Fonts.FontType.regular,
                    }}
                    variant="body1">
                    {getCurrencySymbol()}0
                    <Typography variant="caption" style={{ fontSize: '18px' }}>
                      {' '}
                      today
                    </Typography>
                  </Typography>
                  then{' '}
                  <Typography
                    sx={{
                      fontSize: '22px',
                      color: colors.OFF_BLACK,
                      fontWeight: 'bold',
                      lineHeight: 1,
                      fontFamily: Fonts.FontType.regular,
                    }}
                    variant="caption">
                    {getCurrencySymbol()}
                    {perCycleAmountBasedOnDuration(
                      finalPlanObj.perCycleAmount,
                      finalPlanObj.intervalCount + ' ' + finalPlanObj.interval
                    )}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '17px',
                      fontFamily: Fonts.FontType.regular,
                    }}>
                    /{finalPlanObj.interval}
                  </Typography>
                </Box>
                <Box
                  textAlign={'right'}
                  sx={{
                    fontSize: isMobile ? '12px' : '14px',
                    color: colors.OFF_BLACK,
                    fontFamily: Fonts.FontType.regular,
                    fontWeight: 400,
                    lineHeight: 'normal',
                    marginTop: '2px',
                  }}>
                  <BillingText
                    selectedPlanId={selectedPlanId}
                    finalPlanObj={finalPlanObj}
                  />
                </Box>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                color: colors.OFF_BLACK,
                fontFamily: Fonts.FontType.regular,
                marginTop: '10px',
              }}>
              Cancel anytime. You won't be charged if you cancel before{' '}
              {moment().add(10, 'days').format('DD MMMM, YYYY')}.
            </Typography>
            <Divider style={{ margin: '10px 0px' }} />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
          <Box>
            <Typography
              sx={{
                fontSize: isMobile ? '28px' : '32px',
                color: colors.SALAD,
                fontWeight: 600,
                fontFamily: Fonts.FontType.regular,
                lineHeight: 1,
                transform: 'uppercase',
              }}>
              {
                PLAN_NAME_OBJ[
                  finalPlanObj.intervalCount + ' ' + finalPlanObj.interval
                ]
              }
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                color: colors.OFF_BLACK,
                fontWeight: 600,
                textTransform: 'uppercase',
                fontFamily: Fonts.FontType.regular,
                letterSpacing: '2px',
                marginTop: '2px',
              }}>
              {intervalString}
            </Typography>
          </Box>
          {!showFreeTrial && (
            <Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography
                  sx={{
                    fontSize: '34px',
                    color: colors.OFF_BLACK,
                    fontWeight: 600,
                    lineHeight: 1,
                    fontFamily: Fonts.FontType.regular,
                  }}
                  variant="caption">
                  {getCurrencySymbol()}
                  {perCycleAmountBasedOnDuration(
                    finalPlanObj.perCycleAmount,
                    finalPlanObj.intervalCount + ' ' + finalPlanObj.interval
                  )}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{ fontSize: '17px', fontFamily: Fonts.FontType.regular }}>
                  /{finalPlanObj.interval}
                </Typography>
              </Box>
              <Box
                textAlign={'right'}
                sx={{
                  fontSize: isMobile ? '12px' : '14px',
                  color: colors.OFF_BLACK,
                  fontFamily: Fonts.FontType.regular,
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginTop: '2px',
                }}>
                <BillingText
                  selectedPlanId={selectedPlanId}
                  finalPlanObj={finalPlanObj}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Typography
          sx={{
            fontSize: '16px',
            color: colors.BLUEBERRY,
            fontWeight: 400,
            margin: '10px 0px',
            fontFamily: Fonts.FontType.regular,
            lineHeight: '20px',
          }}>
          {planType === 'standard' ? standardShortDesc() : premiumShortDesc()}
        </Typography>
        <Box
          onClick={() => setShowDetails(!showDetails)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            color: colors.SALAD,
            fontFamily: Fonts.FontType.regular,
          }}>
          <Typography
            sx={{
              fontSize: '16px',
              color: colors.SALAD,
              fontWeight: 600,
              margin: '10px 0px',
              fontFamily: Fonts.FontType.regular,
              lineHeight: '20px',
              textDecoration: 'underline',
            }}>
            {showDetails ? 'Less Details' : 'View Details'}
          </Typography>
          {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Box>
        {showDetails && (
          <Box sx={{ marginTop: '10px', transitionDuration: '1s' }}>
            {currentPlanDescriptions.map(
              (points: { title: string; description: string }) => {
                return (
                  <Box
                    sx={{ display: 'flex', marginBottom: '16px' }}
                    key={points.title}>
                    <CheckIcon
                      sx={{ color: colors.SALAD, marginRight: '10px' }}
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          color:
                            points.title === 'Personal coaching'
                              ? colors.SALAD
                              : colors.OFF_BLACK,
                          fontWeight: 600,
                          fontFamily: Fonts.FontType.regular,
                        }}>
                        {points.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: colors.OFF_BLACK,
                          fontFamily: Fonts.FontType.regular,
                          width: '70%',
                        }}>
                        {points.description}
                      </Typography>
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PlanDetail;
