import React, { useEffect, useState } from 'react';

// individual step components
import CreateAccount from '../create-account/CreateAccount';
import PaymentPage from '../payment-new/PaymentPage';
import SubscriptionPage from '../subscription-new/SubscriptionPage';

// HOC
import { signupStepWrapper } from './wrapper';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import history from 'src/lib/history';
import { setFreeTrial } from 'src/store/signup';
import { isFreeTrialURL } from 'src/lib/utils';

interface StepperProps {
  currentStep: number;
}

const SubscriptionStep = signupStepWrapper(SubscriptionPage, 1);
const CreateAccountStep = signupStepWrapper(CreateAccount, 2);
const PaymentStep = signupStepWrapper(PaymentPage, 3);

const Stepper: React.FC<StepperProps> = ({ currentStep }) => {
  // Redux Selectors
  const user = useAppSelector(state => state.auth?.user);
  const isAuthenticated = useAppSelector(state => state.auth?.isAuthenticated);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    const location = { pathname: '/account/profile' };

    if (
      isAuthenticated &&
      isInitialRender &&
      user?.status !== 'PROSPECT' &&
      user?.status !== 'INACTIVE' &&
      user?.status !== 'FINAL' &&
      user?.payment_provider === 'Stripe'
    ) {
      setIsInitialRender(false);
      history.push(location);
    }
  }, [isAuthenticated, user, isInitialRender]);

  useEffect(() => {
    const confirmMessage =
      'Are you sure you want to leave? Your data may be lost.';

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = confirmMessage; // This message will be shown in the confirmation popup
      return confirmMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    if (currentStep === 3) {
      //if on payment page, remove listener as it will create extra click For example on after pay
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentStep]);

  return (
    <div>
      <SubscriptionStep currentStep={currentStep} />
      <CreateAccountStep currentStep={currentStep} />
      <PaymentStep currentStep={currentStep} />
    </div>
  );
};

const SignupSteps = () => {
  const { currentStep } = useAppSelector(state => state.signUp);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isFreeTrialURL()) {
      dispatch(setFreeTrial(true));
    } else {
      dispatch(setFreeTrial(false));
    }
  }, [dispatch]);

  return <Stepper currentStep={currentStep} />;
};

export default SignupSteps;
