/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';

// components
import SignUpStepper from 'src/components/stepper';
import { Layout } from 'src/components/layout-wrapper/Layout';
import PromoCode from 'src/components/fields/promo-code/promo-code';
import SubscriptionPlanPopup from './SubscriptionPlanPopup';

// utils & types
import {
  filterStipeErrorMessage,
  getEcommerceDataProperties,
  getFullPlanObj,
  useViewport,
} from 'src/lib/utils';
import { Coupon, WeeksPurchased } from '../deprecated/payment/types';

// styles & assets
import colors from 'src/styles/equalution/colors';
import PlanChangeOption from './components/plan-change-option';
import { useAppSelector } from 'src/store/hooks';

import CardComponent from '../signup/steps/payment/card-component';
import { PaymentMethod, loadStripe } from '@stripe/stripe-js';
import { TagData } from 'src/store/signup-workflow/types';
import User from 'src/models/User';
import { validateCoupons } from '../signup/signup';
import paymentAPI from '../deprecated/payment/api';
import history from 'src/lib/history';
import { checkAuthentication } from 'src/lib/auth/actions';
import { PLANS, USER_STATUS, projectCouponCode } from '../constants';
import {
  setIsFutureCommencement,
  setNoPaymentIntentAvailable,
} from 'src/store/signup';
import { EVENTS, track } from 'src/util/track';
import { setPaymentProcessing } from 'src/store/payment';
import { checkQuizStatus } from 'src/services/quiz-service';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export interface EffectivePlanObjType {
  id: string;
  productId: string;
  weeklyMealPlanQty: number;
  unitAmountCents: number;
  unitAmount: string;
  unitAmountPerWeek: number;
  unitAmountPerDay: number;
  weeksPurchased: WeeksPurchased;
  interval: string;
  intervalCount: number;
  perCycleAmount: string | number;
}

const PaymentPage = () => {
  const { isMobile, isDesktop, width } = useViewport();

  // states
  const [popupVisible, setPopupVisible] = useState(false);
  const [coupon, setCoupon] = useState<Coupon | undefined>();

  // conditional constants
  const showDivider = width > 950;

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [totalPrice, setTotalPrice] = useState(0);

  const [walletPaymentIntentId, setWalletPaymentIntentId] =
    useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [effectivePlanObj, setEffectivePlanObj] =
    useState<EffectivePlanObjType>();

  const tagId = parseInt(coupon?.metadata?.tagId || '');

  // redux selectors
  const user = useAppSelector(state => state.auth?.user);
  const {
    availablePlans,
    selectedPlanId,
    addonWeek,
    isChallengeAccepted,
    futureCommencement,
    challengeData,
    isFitherProjectUser,
    allPlansSorted,
    noIntentAvailable,
    showFreeTrial,
  } = useAppSelector(state => state.signUp);
  const dispatch = useDispatch();

  const showTwoStepProcess = useMemo(() => {
    const isProspectUser =
      user?.status === USER_STATUS.PROSPECT ||
      user?.status === USER_STATUS.INACTIVE;
    return isProspectUser || isFitherProjectUser;
  }, []);

  useEffect(() => {
    let discountAmount = 0;
    const finalPlanObj = getFullPlanObj(
      allPlansSorted,
      selectedPlanId,
      addonWeek
    );
    if (finalPlanObj) {
      if (coupon) {
        if (coupon.amountOff > 0) {
          discountAmount = coupon.amountOff / 100;
        } else if (coupon.percentOff > 0) {
          discountAmount =
            parseFloat(finalPlanObj.unitAmount) * (coupon.percentOff / 100);
        }

        const remainingAmount =
          parseFloat(finalPlanObj?.unitAmount) - discountAmount > 0
            ? parseFloat(finalPlanObj?.unitAmount) - discountAmount
            : 0;
        setEffectivePlanObj({
          ...finalPlanObj,
          perCycleAmount: (
            parseFloat(remainingAmount.toString()) / finalPlanObj.intervalCount
          ).toFixed(2),
          unitAmount: remainingAmount.toString(),
        });
        setTotalPrice(remainingAmount);
      } else {
        setEffectivePlanObj(finalPlanObj);
        setTotalPrice(parseFloat(finalPlanObj.unitAmount));
      }
    }
  }, [coupon, availablePlans, selectedPlanId, addonWeek]);

  const Header = useMemo(
    () => (
      <Stack mb={isMobile ? '24px' : '64px'} alignItems={'center'}>
        <Box width={showTwoStepProcess ? 70 : 100}>
          <SignUpStepper
            activeStep={showTwoStepProcess ? 2 : 3}
            steps={showTwoStepProcess ? ['1', '2'] : ['1', '2', '3']}
          />
        </Box>
        <Stack alignItems={'center'}>
          <Box
            textAlign={'center'}
            sx={{
              fontSize: isMobile ? '20px' : '29px',
              fontWeight: 800,
              marginTop: '20px',
            }}>
            {isMobile ? 'Order Summary' : 'Select your payment'}
          </Box>
        </Stack>
      </Stack>
    ),
    [isMobile]
  );

  const findPlanName = (planId: string): string => {
    if (planId.includes('Standard')) {
      return PLANS.STANDARD_PLAN;
    }
    if (planId.includes('Premium')) {
      return PLANS.PREMIUM_PLAN;
    }
    if (planId.includes('Daily')) {
      return PLANS.DAILY_TARGETS_ONLY_PLAN;
    }
    return '';
  };

  interface SubscriptionType {
    user: User;
    paymentMethod: PaymentMethod;
    priceId: string;
    coupon: Coupon | undefined;
    isChallengeAccepted: boolean;
    totalPrice: string;
    futureCommencement: boolean;
    tagId: number;
    productId: string;
    challengeObject: TagData | null;
    walletPaymentIntentId: string;
    noIntentAvailable: boolean;
  }
  const StartSubscription = async (props: SubscriptionType) => {
    const {
      user,
      paymentMethod,
      priceId,
      coupon,
      isChallengeAccepted,
      totalPrice,
      futureCommencement,
      tagId,
      productId,
      challengeObject,
      walletPaymentIntentId,
      noIntentAvailable,
    } = props;
    const errorMsg = validateCoupons(priceId, coupon?.metadata);
    if (errorMsg) {
      return { errorMsg };
    }

    let quizFinalStatus = false;

    //checking quiz status before final payment
    const quizStatusRes = await checkQuizStatus({ email: user.email });
    if (quizStatusRes.status === 200 && quizStatusRes?.data?.data) {
      quizFinalStatus = true;
    }

    // Create Customer
    const { data } = await paymentAPI.post('stripe/customer/create', {
      email: user.email,
      userID: user.id,
    });

    let purchaseInvoicePayload: any = {
      paymentMethodID: paymentMethod.id,
      customerID: data.customer?.id,
      productId: productId,
      priceIDs: [priceId],
      promoID: coupon?.id || null,
      userID: user.id,
      isChallenge: !!isChallengeAccepted,
      paymentIntentId: walletPaymentIntentId,
      isCardPayment: true, //make it true only if is card payment
      futureCommencement,
      isQuiz: quizFinalStatus,
      isFreeTrial: showFreeTrial,
    };
    if (!!isChallengeAccepted && challengeObject?.id) {
      purchaseInvoicePayload.challenge_ids = [challengeObject.id];
    }

    // Process Payment
    if (noIntentAvailable) {
      const purchaseResponse = await paymentAPI
        .post('stripe/invoice/purchase', purchaseInvoicePayload)
        .catch(error => {
          if (error.response) {
            return error.response.data;
          }
        });
      if (purchaseResponse?.message) {
        return { errorMsg: filterStipeErrorMessage(purchaseResponse.message) };
      }
    } else {
      const walletResponse = await paymentAPI
        .post('stripe/invoice/purchase/wallets', purchaseInvoicePayload)
        .catch(error => {
          if (error.response) {
            return error.response.data;
          }
        });
      if (walletResponse?.message) {
        return { errorMsg: filterStipeErrorMessage(walletResponse?.message) };
      }
    }

    const trackProperties =
      getEcommerceDataProperties(selectedPlanId, allPlansSorted, {
        payment_type: 'CREDIT_DEBIT_CARD',
        coupon_id: coupon?.id,
        coupon_name: coupon?.name,
        total_amount: Number(effectivePlanObj?.unitAmount),
        per_cycle_amount: Number(effectivePlanObj?.perCycleAmount),
        value: Number(effectivePlanObj?.unitAmount),
      }) ?? {};

    track(EVENTS.addPaymentInfo, { ecommerce: null });
    track(EVENTS.addPaymentInfo, trackProperties);

    // Update Google Analytics
    if (user.status !== 'PROSPECT') {
      history.push('/success?ref=TransactionComplete', {
        tagId,
        futureCommencement,
        priceId,
        coupon,
        totalPrice,
        dataLayer: track(EVENTS.programPurchase, {
          currency: 'AUD',
          value: totalPrice,
          purchaseComplete: 'ClientRenewal',
          priceIDs: [priceId],
          isFitherProjectUser,
        }),
      });
    } else {
      history.push('/success?ref=TransactionComplete', {
        tagId,
        futureCommencement,
        priceId,
        coupon,
        totalPrice,
        dataLayer: track(EVENTS.programPurchase, {
          currency: 'AUD',
          value: totalPrice,
          purchaseComplete: 'ClientNewPurchase',
          priceIDs: [priceId],
          isFitherProjectUser,
        }),
      });
    }
  };
  const handleSubmit = async (): Promise<void> => {
    setErrorMessage('');
    if (!effectivePlanObj) {
      return;
    }
    try {
      if (user && paymentMethod) {
        const result = await StartSubscription({
          user,
          paymentMethod,
          priceId: effectivePlanObj.id,
          coupon,
          isChallengeAccepted,
          totalPrice: totalPrice.toString(),
          futureCommencement,
          tagId,
          productId: effectivePlanObj.productId,
          challengeObject: challengeData,
          walletPaymentIntentId,
          noIntentAvailable,
        });
        if (result?.errorMsg) {
          setErrorMessage(result.errorMsg);
          dispatch(setPaymentProcessing(false));
          setPaymentMethod(undefined);
        } else {
          dispatch(checkAuthentication());
        }
      } else {
        dispatch(checkAuthentication());
      }
    } catch (e) {
      dispatch(setPaymentProcessing(false));
      setPaymentMethod(undefined);
      if (typeof e === 'string') {
        setErrorMessage(e);
      } else if (e instanceof Error) {
        setErrorMessage(e.message);
      }
    }
  };

  const PlanAndPromoCode = useMemo(
    () => (
      <Stack width={'365px'} gap={'16px'} justifyContent={'flex-start'}>
        {!isMobile && (
          <Box textAlign={'center'} fontSize={'20px'} fontWeight={800}>
            Order Summary
          </Box>
        )}
        {effectivePlanObj && (
          <PlanChangeOption
            onChangeClick={() => setPopupVisible(true)}
            finalPlanObj={effectivePlanObj}
          />
        )}
        {!showFreeTrial && (
          <Box>
            <Box
              sx={{ textTransform: 'uppercase' }}
              fontSize={'12px'}
              color={colors.BLUEBERRY_HALF}>
              Promo Code
            </Box>
            {effectivePlanObj && (
              <PromoCode
                prefillCode={isFitherProjectUser ? projectCouponCode : ''}
                priceId={effectivePlanObj.id}
                coupon={coupon}
                setCoupon={setCoupon}
                finalPrice={parseFloat(effectivePlanObj.unitAmount.toString())}
                isFromSignUp={true}
                productId={effectivePlanObj.productId}
                futureCommencement={futureCommencement}
                setFutureCommencement={value =>
                  dispatch(setIsFutureCommencement(value))
                }
              />
            )}
          </Box>
        )}
      </Stack>
    ),
    [coupon, isDesktop, isMobile, effectivePlanObj]
  );
  const PaymentSection = () => (
    <Stack maxWidth={'100%'} flex={2} alignItems={'center'} gap={'32px'}>
      {isMobile && (
        <Box textAlign={'center'} fontSize={'20px'} fontWeight={800}>
          Select your payment
        </Box>
      )}
      <Box
        sx={{ display: 'flex', flex: 1, width: isMobile ? '320px' : '550px' }}>
        {effectivePlanObj && (
          <Elements stripe={stripePromise}>
            <CardComponent
              selectedPlan={selectedPlanId}
              setPaymentMethod={setPaymentMethod}
              handleSubmit={handleSubmit}
              paymentMethod={paymentMethod}
              priceId={effectivePlanObj.id}
              finalPrice={parseFloat(effectivePlanObj.unitAmount.toString())}
              planName={findPlanName(selectedPlanId)}
              coupon={coupon}
              isChallengeAccepted={isChallengeAccepted}
              futureCommencement={futureCommencement}
              tagId={tagId}
              challengeObject={challengeData}
              productId={effectivePlanObj.productId}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              setWalletPaymentIntentId={setWalletPaymentIntentId}
              popupVisible={popupVisible}
              noIntentAvailable={noIntentAvailable}
              setNoIntentAvailable={(value: boolean) => {
                dispatch(setNoPaymentIntentAvailable(value));
              }}
            />
          </Elements>
        )}
      </Box>
    </Stack>
  );

  if (!effectivePlanObj) {
    return null;
  }

  return (
    <>
      <Layout module="login" hideHeader={isMobile && popupVisible}>
        <Stack
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            padding: '1.5rem',
            marginX: 'auto',
            ...(isDesktop && { width: '1120px' }),
            ...(isMobile && { width: '320px' }),
          }}>
          {Header}
          <Stack
            alignItems={'flex-end'}
            justifyContent={'center'}
            direction={'row'}
            flex={1}
            flexWrap={'wrap-reverse'}>
            {PaymentSection()}
            {showDivider && (
              <Stack
                mx={'40px'}
                height={'470px'}
                borderLeft={`0.3px solid ${colors.OFF_BLACK}`}
              />
            )}
            {PlanAndPromoCode}
          </Stack>
          <Box height={'100px'} />
        </Stack>
      </Layout>
      {popupVisible && (
        <SubscriptionPlanPopup
          setPopupVisible={setPopupVisible}
          user={user}
          planRequest={'planRequest'}
        />
      )}
    </>
  );
};

export default PaymentPage;
